export enum TargetFlags {
  None = 0,
  Adult = 4,
  Vlog = 8,
  Gaming = 16,
  Education = 64,
  Religion = 128,
  Music = 256,
}

export enum ChannelState {
  Idle,
  RunningOk,
  RunningInitError,
  RunningConnectError,
}

export type DeviceType = 'win' | 'and' | 'ios' | 'web' | 'ext';

export type Target = {
  Id: string | null;
  Name: string;
  WebUrl: string;
  Hint: string;
  Flags: TargetFlags;
  DefaultRtmpUrl: string;
};

export type Channel = {
  TargetId: string;
  Name: string;
  Key: string;
  WebUrl: string;
  RtmpUrl: string;
  IsOn: boolean;
  State: ChannelState;
  Bitrate: number;
  Timer: string;
  TranscoderId?: string;
  Temporary?: boolean;
  BitrateWarning?: string;
  TargetMode: TargetMode;
  AutoLoginState: AutoLoginState;
};

export enum TargetMode {
  ManualKey = 0,
  AutoLogin = 1,
}

export enum AutoLoginState {
  Unknown = 0,
  InProgress = 1,
  Authenticated = 2,
  NotAuthenticated = 3,
  KeyObtained = 4,
  KeyNotFound = 5,
}

type VideoInputPreview = {
  W: number;
  H: number;
  Data: ReadonlyArray<number>;
};

type VideoFilter = {
  Name: string;
  Value: number;
};

type VideoFilters = ReadonlyArray<VideoFilter>;

enum VideoInputCapabilityFormat {
  Raw = 0,
  Empty = 1,
  MJpeg = 2,
  Unknown = 3,
  H264 = 4,
  I420 = 5,
  NV12 = 6,
}

type VideoInputCapability = {
  W: number;
  H: number;
  MinF: number;
  MaxF: number;
  Fmt: VideoInputCapabilityFormat;
};

type VideoInputCapabilities = {
  Caps: ReadonlyArray<VideoInputCapability>;
};

export type VideoInput = {
  Preview: VideoInputPreview;
  Filters: VideoFilters;
  Capabilities: VideoInputCapabilities;
};

enum InputType {
  USB = 0,
  Virtual = 1,
  Remote = 2,
}

enum InputState {
  Unknown = 0,
  Ready = 1,
  InUseByOtherApp = 2,
  Removed = 3,
  Failed = 4,
  ObsIsNotStarted = 5,
  Running = 6,
  RemoteDeviceOffline = 10,
  RemoteDeviceInactive = 11,
}

export type Input = {
  Name: string;
  Owner: string;
  Type: InputType;
  State: InputState;
};

export type AudioInput = Input;

export enum DeviceState {
  Inactive,
  Offline,
  Online,
}

type IntRect = {
  L: number;
  T: number;
  W: number;
  H: number;
};

enum AppWindowState {
  Normal,
  Compact,
  FullScreen,
  Maximized,
  Minimized,
}

enum VpnBehavior {
  Manually,
  AppStart,
}

type DeviceSettings = {
  NormalWnd: IntRect;
  CompactWnd: IntRect;
  AppWindowState: AppWindowState;
  DisableTopMost: boolean;
  TopMostExtendedMode: boolean;
  RecordingsPath: string;
  VpnBehavior: VpnBehavior;
};

type ProcessLoad = {
  Name: string;
  Load: number;
};

type IndicatorBase = {
  Enabled: boolean;
  State: IndicatorState;
};

type IndicatorCpu = {
  Load: string;
  Top: ReadonlyArray<ProcessLoad>;
} & IndicatorBase;

type IndicatorCloudIn = {
  Bitrate: number;
  Errors: number;
} & IndicatorBase;

type IndicatorCloudOut = {
  Bitrate: number;
  Errors: number;
  Drops: number;
} & IndicatorBase;

type IndicatorEncoder = {
  QueueSize: number;
  InputErrors: number;
  InputFps: number;
  InputTargetFps: number;
} & IndicatorBase;

enum VpnState {
  Idle,
  Connecting,
  Reconnecting,
  Connected,
}

export enum IndicatorState {
  Disabled,
  Ok,
  Warning,
  Warning2,
  Error,
  Error2,
  ExceededBitrate,
}

type VpnData = {
  User: string;
  Pwd: string;
  Url: string;
};

type IndicatorVpn = {
  Sent: number;
  Received: number;
  State: VpnState;
} & IndicatorBase;

export type DeviceIndicators = {
  Cpu: IndicatorCpu;
  CloudIn: IndicatorCloudIn;
  CloudOut: IndicatorCloudOut;
  Encoder: IndicatorEncoder;
  Vpn: IndicatorVpn;
};

export type Device = {
  Name: string;
  State: DeviceState;
  PreviewVideo: boolean;
  PreviewAudio: boolean;
  RequireOutgest: boolean;
  DisplayVideoHidden: boolean;
  AssignedOutgest: string;
  DeviceSettings: DeviceSettings;
  KPIs: DeviceIndicators;
  VpnData: VpnData;
  VpnRequested: boolean;
  VpnState: VpnState;
  VpnServerIpAddress: string;
  DisconnectRequested: boolean;
  VideoInputs: Record<string, DeviceVideoInput>;
  AudioInputs: Record<string, any>;
  PreviewSources: boolean;
  Type: DeviceType;
  HidePreview: boolean;
};

export type DeviceVideoInput = {
  Name: string;
  State: DeviceState;
  Type: number;
};

type IngestData = {
  Type: string;
  Output: string;
  Options: string;
};

export type Ingest = {
  Data: IngestData;
  Owner: string;
  Type: number;
};

type OutgestData = {
  Type: string;
  Output: string;
  DeviceId: string | null;
  Options: string;
};

export type Outgest = {
  Data: OutgestData;
};
export type Resource = {}; // ?

export type Resolution = {
  Height: number;
  Width: number;
};

export enum StreamingToCloudBehavior {
  AppStart,
  FirstChannel,
  Manually,
}

export enum EncoderType {
  Auto,
  Hardware,
  Software,
}

export enum EncoderQuality {
  Speed = -1,
  Balanced,
  BalancedQuality,
  Quality,
}

export type Settings = {
  TargetFilter: number;
  RequestedVideo: string;
  SelectedVideo: string;
  RequestedAudio: string;
  SelectedAudio: string;
  Bitrate: number;
  Fps: number;
  Resolution: Resolution;
  StreamingToCloud: StreamingToCloudBehavior;
  StreamingToCloudStarted: boolean;
  EncoderType: EncoderType;
  EncoderQuality: EncoderQuality;
  IsRecordingRequested: boolean;
  NoStreamWithoutVpn: boolean;
  SelectedScene: string;
  BitrateWarning: string;
  TargetPreference: TargetPreference;
  ShowPreviewMobile: boolean;
};

export enum TargetPreference {
  None = 0,
  Unknown = 1,
  Adult = 2,
  Vlog = 3,
}

export interface Transcoder {
  Bitrate: number;
  Fps: number;
  Resolution: Resolution;
}

export interface Scene {
  Items: Record<string, SceneItem>;
  Audios: Record<string, SceneAudio>;
  Owner: string;
}

export interface SceneAudio {
  Muted: boolean;
  Volume: number;
  Source: SceneAudioSource;
}

type DeviceName = any;

export interface SceneAudioSource {
  DesktopAudio: boolean;

  DeviceName: DeviceName;
}

export interface SceneRect {
  T: number;
  L: number;
  W: number;
  H: number;
}

export interface SceneItem {
  Rect: SceneRect;
  Ptz: SceneRect;
  Visible: boolean;
  ZOrder: number;
  Source: SceneItemSource;
  Filters: SceneItemFilter[];
}

export interface SceneItemSource {
  Device: SceneItemSourceDevice | null;
  Web: SceneItemSourceWeb | null;
  Lovense: SceneItemSourceLovense | null;
  Image: SceneItemSourceImage | null;
  Capture: SceneItemSourceCapture | null;
}

export interface SceneItemSourceDevice {
  DeviceName: DeviceName;
}

export interface SceneItemSourceWeb {
  Url: string;
  Width: number;
  Height: number;
}

export interface SceneItemSourceLovense {}

export interface SceneItemSourceImage {
  ResourceId: string;
}

export enum SceneItemSourceCaptureType {
  Display,
  Window,
  Selection,
}

export interface SceneItemSourceCapture {
  Name: string;
  Handle: string;
  Type: SceneItemSourceCaptureType;
}

export interface SceneItemFilter {
  Type: SceneItemFilterType;
  Value: number;
  LutResourceId: string;
}

export enum SceneItemFilterType {
  None,

  HFlip,

  Warm,
  Cold,
  Dark,
  Light,
  Vintage,
  Sepia,
  Grayscale,

  Contrast,
  Brightness,
  Saturation,
  Gamma,

  Hue,
  Opacity,
  Sharpness,

  UserLut,
}

export interface Platforms {
  PlatformInfos: PlatformInfo[];
  Platforms: Record<string, Platform>;
  Chats: Record<string, Chat>;
}

export interface Platform {
  AuthenticationType: PlatformAuthenticationType;
  AuthenticationData: AuthenticationData;
  StaticAuthenticationData: StaticAuthenticationData;
  UserPasswordAuthentication: UserPasswordAuthentication;
  BrowserTokenAuthentication: BrowserTokenAuthentication;
  ResetCounter: number;
}

export interface Chat {
  TargetId: string;
  State: ChatState;
  StateDetails: string;
}

export interface PlatformInfo {
  TargetId: string;
  Flags: PlatformInfoFlags;
}

export enum PlatformInfoFlags {
  GetKey = 1,
  Chats = 2,
}

export enum PlatformAuthenticationType {
  OAuthServer = 0,
  UserPassword = 1,
  WebBrowser = 2,
}

export enum ChatState {
  Initializing = 0,
  Connecting = 1,
  NotAuthenticated = 2,
  Connected = 3,
  ConnectionFailed = 4,
  ConnectionNotInitialized = 5,
}

export interface AuthenticationData {
  OAuthAuthenticationUrl: string;
}

export interface StaticAuthenticationData {
  WebBrowserLoginSearch: WebBrowserLoginSearch;
}

export interface UserPasswordAuthentication {
  User: string;
  Password: string;
  CanSave: boolean;
}

export interface BrowserTokenAuthentication {
  KeyValues: Record<string, string>;
}

export interface WebBrowserLoginSearch {
  StartUrl: string;
  Entries: WebBrowserLoginSearchEntry[];
}

export interface WebBrowserLoginSearchEntry {
  parameter: string;
  Url: string;
  Method: string;
  header: string;
  regex: string;
}

export interface Socket {
  Kind: number;
  Url: string;
}

export type Document = {
  Targets: Record<string, Target>;
  Channels: Record<string, Channel>;
  VideoInputs: Record<string, VideoInput>;
  AudioInputs: Record<string, AudioInput>;
  Devices: Record<string, Device>;
  Ingests: Record<string, Ingest>;
  Outgests: Record<string, Outgest>;
  Resources: Record<string, Resource>;
  Scenes: Record<string, Scene>;
  Settings: Settings;
  Transcoders: Record<string, Transcoder>;
  Platforms: Platforms;
  Sockets?: Record<string, Socket>;
};
