import HomeIcon from '../../assets/icons/home-circle.svg';
import StreamIcon from '../../assets/icons/broadcast.svg';
import StatisticsIcon from '../../assets/icons/chart-bar.svg';
import ProfileIcon from '../../assets/icons/account.svg';
import TariffIcon from '../../assets/icons/currency-usd.svg';
import HelpIcon from '../../assets/icons/help-circle-outline.svg';
import ContactIcon from '../../assets/icons/message-processing-outline.svg';
import SparklingIcon from '../../assets/icons/sparkling.svg';
import GiftIcon from '../../assets/icons/gift.svg';
import LayersIcon from '../../assets/icons/layers.svg';
import { Route } from './WebNavigation';
import { TargetPreference } from '#/socket/document.type';

export const ROUTES: Route[] = [
  {
    name: 'Home',
    screens: ['Home'],
    href: '/home',
    icon: HomeIcon,
  },
  {
    name: 'Live stream',
    screens: ['ActiveStream', 'Stream'],
    href: '/stream',
    icon: StreamIcon,
    activeIfStreaming: true,
    businessIndent: true,
  },
  {
    name: 'Overlays',
    screens: ['Overlays'],
    href: '/overlays',
    icon: LayersIcon,
  },
  {
    name: 'Statistics',
    screens: ['Statistics'],
    href: '/statistics',
    icon: StatisticsIcon,
    hideForBusinessUsers: true,
    businessIndent: true,
  },
  {
    name: 'Profile',
    screens: ['Profile'],
    href: '/profile',
    icon: ProfileIcon,
    hideForBusinessUsers: true,
    businessIndent: true,
  },
  {
    name: 'Pricing plans',
    screens: ['Tariff'],
    href: '/tariff',
    icon: TariffIcon,
    hideForBusinessUsers: true,
    businessIndent: true,
  },
  {
    name: 'Refer a friend',
    screens: ['ReferAFriend'],
    href: '/refer-a-friend',
    icon: GiftIcon,
    hideForBusinessUsers: true,
    hideForBusiness: true,
    businessIndent: true,
  },
  {
    name: 'Help',
    screens: [],
    href: 'https://docs.streamster.io/',
    icon: HelpIcon,
  },
  {
    name: 'Contact us',
    screens: [],
    href: 'https://help.streamster.io/support/tickets/new',
    icon: ContactIcon,
  },
  {
    name: 'CamGems',
    screens: [],
    href: 'https://camgems.com/',
    icon: SparklingIcon,
    showForTarget: TargetPreference.Adult,
  },
];
